import Loading from "@/components/Loading";
import { CurrentUserInfo, getCurrentUser } from "@/services/user";
import author from "@/utils/lib-author";
import appConfig from "@/utils/appConfig";
import themeConfig from "@/utils/themeConfig";
import { AGREEMENT } from "@/constants/pages";
import { history, useModel } from "umi";
import checkRedirect from "./utils/device-redirect";
import setOverseasToken from "./utils/lib-author/customToken";

export type InitialState = {
  currentUser?: CurrentUserInfo;
};

// 无需拉取初始化数据的页面
const INITIAL_BLACK_LIST: string[] = [AGREEMENT];

export async function getInitialState(): Promise<InitialState> {
  const state: InitialState = {};

  const inBlackList = INITIAL_BLACK_LIST.some((path) => history.location.pathname.startsWith(path));
  if (inBlackList) return state;

  await appConfig.init();

  await themeConfig.init();

  // 移动设备跳转
  const WEBSITE_H5 = appConfig.get("WEBSITE_H5");
  checkRedirect({ WEBSITE_H5: WEBSITE_H5 });

  await setOverseasToken();

  if (author.getToken()) {
    state.currentUser = await getCurrentUser();
  }
  return state;
}

export const initialStateConfig = {
  loading: <Loading />,
};
