import { request } from 'umi';
import { API_VERSION, SERVER } from '@/services';

export type ProductLimit = {
  // 已购数量
  hadPurchase: number;
  // 可购数量
  leftCanPurchase: number;
  // 限购数量
  maxPurchaseLimit: number;
  // 每单最大可购买数量
  oncePurchaseLimit: number;
  // 商品定义ID
  productDefId: number;
}

export function getProductLimitByDefId(productDefId: number | string, userId: string, srcId: string) {
  return request<ProductLimit>(
    `/${SERVER.product}/${API_VERSION}/productDef/noAuth/productDefPurchaseLimit/${productDefId}`,
    {
      method: 'GET',
      params: { userId, srcId },
      useAuth: false,
      silent: true
    },
  );
}
