import { ResponseError } from 'umi-request';
import { notification } from 'antd';

const bizErrorHandler = (error: ResponseError) => {
  const { data } = error;

  notification.error({
    message: '请求失败',
    description: data.message,
  });
};

export default bizErrorHandler;
