import socket, { EventSourceListener } from "@/utils/lib-socket";
import { useModel } from "umi";
import { useEffect, useRef } from "react";

export enum SocketAction {
    // PRODUCT = "refresh_collections_assets",
    // LOG = "global_notification",
    MSG = "message",
    TRANSFER_RECEIVED = "transferReceived",
    AIRDROP_RECEIVED = "airDropReceived", // 空投
    SCANACTIVITYREWARD_RECEIVED = "scanActivityRewardReceived", // 扫码领取
}

export default function () {
    const userID = useModel("@@initialState", (s) => s.initialState?.currentUser?.id);
    const actionListenerMap = useRef<Partial<Record<SocketAction, Set<Function>>>>({});
    const onMessage: EventSourceListener = (...args) => {
        const [actionType, payloadStr] = args as unknown as [SocketAction, string];
        console.log('socket msg: ', args);
        // const payload = actionType === SocketAction.TRANSFER_RECEIVED ? JSON.parse(payloadStr) : payloadStr;
        // const actionType: Action = event.data;
        if (!actionType) return;
        actionListenerMap.current[actionType]?.forEach((fn) => fn(payloadStr));
    };

    useEffect(() => {
        if (!userID) {
            actionListenerMap.current = {};
            return;
        }
        socket?.create(onMessage);
    }, [userID]);

    /**
     * 添加监听函数
     * @param action 类型
     * @param listener 监听函数
     * */
    const addActionListener = (action: SocketAction, listener: Function) => {
        if (actionListenerMap.current[action]) {
            const hasListener = actionListenerMap.current[action]?.find((e) => e === listener);
            if (hasListener) {
                return;
            }
            actionListenerMap.current[action]?.push(listener);
        }
        if (!actionListenerMap.current[action]) {
            actionListenerMap.current[action] = [listener];
        }
    };

    /**
     * 移除监听函数
     * @param action 类型
     * @param [listener] 不指定时移除所有该类型的监听函数
     * */
    const removeActionListener = (action: SocketAction, listener?: Function) => {
        if (!actionListenerMap.current[action]) {
            return;
        }
        const newListener = actionListenerMap.current[action]?.filter((e) => e !== listener);
        actionListenerMap.current[action] = newListener;
    };

    return {
        addActionListener,
        removeActionListener,
    };
}
