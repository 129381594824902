import { useModel } from "umi";
import { functionTypeType, getRealNameConfigList, getRealNameConfigType } from "@/services/config";
import React from "react";

export default function realNameCert() {
  const [list, setList] = React.useState<getRealNameConfigType[]>([]);
  const currentUser = useModel("@@initialState", (e) => e.initialState?.currentUser);

  const getDatasource = () => {
    if (!currentUser?.id) {
      return;
    }
    getRealNameConfigList().then((res) => {
      setList(res);
    });
  };

  /**
   * @param functionType 10-交易购买(市场、盲盒)；20-参加空投(申领模式-空投报名影响)；30-参与合成(合成)；40-发起转赠(商品转赠、权益转赠、分享转赠); 50-现场活动(参与报名)
   * @returns false-否；true-是
   */
  const isNeedRealName = (functionType: functionTypeType) => {
    const index = list.findIndex((e) => e.functionType === functionType);

    if (index === -1) {
      return false;
    }
    // 0-否；1-是
    return list[index].cert === 1;
  };

  /**
  //  * @returns true-全部不需要实名够 false-有一个以上需要实名
   */
  const allNoRealName = list.length > 0 ? list.every((e) => e.cert === 0) : true;

  React.useEffect(() => {
    getDatasource();
  }, [currentUser]);

  return {
    updateList: getDatasource,
    isNeedRealName,
    allNoRealName,
  };
}
