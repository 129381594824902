import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

/** 空投操作管理 */
export type AirDropMgt = {
  /** 运营标签(多个) */
  adminLabels: string[];
  /** 商品图片ID */
  attachId: string;
  /** 作者 */
  author: string;
  /** 作者ID */
  authorId: string;
  /** 作者头像 */
  avatar: string;
  /** 申领开始时间 */
  beginDate: string;
  /** 空投单编号 */
  code: string;
  /** Token合约部署地址 */
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: number;
  /** 申领结束时间 */
  endDate: string;
  /** 空投执行时间 */
  exeDate: string;
  id: number;
  /** 任务ID */
  jobId: number;
  /** 资源标签分组(10:图片类;20:音频类;30:视频类;40:3D类) */
  labelGroup: number;
  /** 商品所有者(地址) */
  owner: string;
  /** 商品所有者ID */
  ownerId: string;
  /** 所有者 */
  ownerName: string;
  /** 作品标签(多个) */
  prodLabels: string[];
  /** 商品名称 */
  prodName: string;
  /** 空投申请数量 */
  qty: number;
  /** 实际空投数量 */
  realQty: number;
  /** 关联-商品定义ID */
  refProductDefId: number;
  /** 可显示时间 */
  showDate: string;
  /** 显示模式：10-即可展示；20-预约展示 */
  showType: number;
  /** 状态：10-空投中；20-空投已完成；30-空投终止 */
  status: number;
  /** 总空投数 */
  totalQty: number;
  /** 合约交易编号 */
  txHash: string;
  /** 空投模式：10-随机模式；20-申领模式 */
  type: number;
  updatedAt: string;
  updatedBy: string;
};

export function getAirDropMgtByRegisterID(registerID: number) {
  return request<AirDropMgt>(
    `/${SERVER.product}/${API_VERSION}/airDropMgt/detail/recently/registerId/${registerID}`,
    {
      method: 'GET',
    },
  );
}
