import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';
import { ProductDefItem } from '@/services/product/queryProductDef';

export type ComposePublishMgtParams = {
  /** 排序规则,是否升序 */
  asc?: boolean;
  /** 合成封面ID */
  attachId?: string;
  /** 合成活动上架单编号 */
  code?: string;
  /** 活动说明 */
  composeDescribe?: string;
  /** 合成活动名称 */
  composeName?: string;
  /** 合成总数 */
  composeQty?: number;
  /** 创建时间 */
  createdAt?: string;
  /** 创建时间范围起点 */
  createdAtBegin?: string;
  /** 创建时间范围终点 */
  createdAtEnd?: string;
  /** 记录创建人账号ID */
  createdBy?: string;
  /** 活动说明富文本ID */
  desRichTextId?: string;
  /** 活动结束时间 */
  endTime?: string;
  /** 排序字段 */
  field?: string;
  /** 下架时间 */
  offDate?: string;
  /** 奖品所有者（地址） */
  owner?: string;
  /** 奖品所有者ID */
  ownerId?: string;
  /** 奖品所有者 */
  ownerName?: string;
  /** 当前页码 */
  pageNum?: number;
  /** 当前页面数据量 */
  pageSize?: number;
  /** 上架时间 */
  publishDate?: string;
  /** 可合成数量(上架中） */
  publishingQty?: number;
  /** 奖励商品定义ID */
  refProductDefId?: number;
  /** 活动开始时间 */
  startTime?: string;
  /** 状态：1-上架（启用）；2-下架（中止）；3-合成结束；-1-已售罄 */
  status?: number;
  /** 更新时间 */
  updatedAt?: string;
  /** 修改时间范围起点 */
  updatedAtBegin?: string;
  /** 修改时间范围终点 */
  updatedAtEnd?: string;
  /** 记录修改人账号ID */
  updatedBy?: string;
  /** 消耗材料总数量（回收） */
  usedQty?: number;

  isDelicate?: boolean;
};

export type ComposeProdInfo = {
  /** 材料图片ID */
  attachId: string;
  /** 作者 */
  author: string;
  /** 作者ID */
  authorId: string;
  /** 作者头像 */
  avatar: string;
  /** 合成所需数量 */
  composeQty: number;
  /** Token合约部署地址 */
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: number;
  id: number;
  /** 材料名称 */
  prodName: string;
  /** 发行数量 */
  qty: number;
  /** 关联合成单ID */
  refComposePublishMgtId: number;
  /** 材料商品定义ID */
  refProductDefId: number;
  /** 合约交易地址 */
  txHash: string;
  updatedAt: string;
  updatedBy: string;
  /** 消耗数量（回收） */
  usedQty: number;
};

export type ComposePublishMgtItem = {
  /** 合成封面ID */
  attachId: string;
  /** 合成活动上架单编号 */
  code: string;
  /** 活动说明 */
  composeDescribe: string;
  /** 合成活动名称 */
  composeName: string;
  /** 合成明细列表 */
  composeProdInfos: ComposeProdInfo[];
  /** 合成总数 */
  composeQty: number;
  /** 创建时间 */
  createdAt: string;
  /** 记录创建人账号ID */
  createdBy: string;
  /** 删除标记 */
  deleted: number;
  /** 活动说明富文本ID */
  desRichTextId: string;
  /** 活动结束时间 */
  endTime: string;
  /** id */
  id: number;
  /** 下架时间 */
  offDate: string;
  /** 奖品所有者（地址） */
  owner: string;
  /** 奖品所有者ID */
  ownerId: string;
  /** 奖品所有者 */
  ownerName: string;
  /** 上架时间 */
  publishDate: string;
  /** 可合成数量(上架中） */
  publishingQty: number;
  /** 奖励商品定义ID */
  refProductDefId: number;
  /** 活动开始时间 */
  startTime: string;
  /** 状态：1-上架（启用）；2-下架（中止）；3-合成结束；-1-已售罄 */
  status: number;
  /** 更新时间 */
  updatedAt: string;
  /** 记录修改人账号ID */
  updatedBy: string;
  /** 消耗材料总数量（回收） */
  usedQty: number;
  //
  adminLabels: string[] | null;
  avatar: string;
  preQty: number;
  preStartTime: string;
  appOptProducts: ProductDefItem[];
};

// 合成单列表
export function getComposePublishMgtPage(params: ComposePublishMgtParams) {
  return request<PaginationResult<ComposePublishMgtItem>>(
    `/${SERVER.product}/${API_VERSION}/composePublishMgt/noAuth/page`,
    {
      method: 'GET',
      params,
      useAuth: false,
    },
  );
}
