import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

import type { ComposePublishMgtItem } from "./getComposePublishMgtPage";

// 合成单列表
export function getComposePublishMgtById(id: string | number) {
  return request<Record<string, any>>(`/${SERVER.product}/${API_VERSION}/activities/noAuth/compose/detail/${id}`, {
    method: "GET",
    useAuth: false,
  });
}
