import device from '@/utils/lib-device';
import joinPath from "@/utils/joinPath";

type Params = {
  WEBSITE_H5: string;
};
type PathMapType = {
  [key: string]: string;
};
// 判断是否是移动端 并进行PC端路由往移动端跳转
const checkRedirect = ({ WEBSITE_H5 }: Params) => {
  if (device.isMobile) {
    let href = location.origin + '/h5/'; // e.g. https://zjcaee-mobile.yuansu.link
    const relativePath = location.href.replace(location.origin, '');
    const relativePathWithIdTempStr =
      location.pathname.substring(0, location.pathname.lastIndexOf('/')) + '/';

    // 注册页（包含注册页和含邀请码的邀请注册）
    if (location.pathname.startsWith('/register')) {
      href += location.pathname.substr(1);
    }

    // 路径尾带ID路由跳转 // 无query
    else if (relativePathWithIdTempStr in pathMapWithId) {
      href = joinPath(href, pathMapWithId[relativePathWithIdTempStr] +
        relativePath.replace(relativePathWithIdTempStr, ''));
    }
    // 一般按表跳转
    else if (relativePath in pathMap) {
      href = joinPath(href, pathMap[relativePath]);
    }

    window.location.href = href;
  }
};

const pathMapWithId: PathMapType = {
  '/goods/details/': '/goods/details/',
  '/blindBox/details/': '/goods/details/blindBox/',
  '/airdrop/details/': '/goods/details/airdrop/',
  '/airdrop/details/invitation/': '/goods/details/airdrop/invitation/',
  '/goods/details/my/': '/goods/details/my/',
};
// 相对地址完全匹配 href-origin
const pathMap: PathMapType = {
  '/login': '/login',
  '/square': '/',
  '/user/home/my?tabs=collection': '/user/collection',
};

export default checkRedirect;
