import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';
import author from '@/utils/lib-author';
import type { AuthInfo } from './postAuthLogin';

export async function postAuthRefresh() {
  const authInfo = await request<AuthInfo>(
    `/${SERVER.user}/${API_VERSION}/auths/refresh`,
    {
      method: 'POST',
      params: {
        refreshToken: author.get('refresh_token'),
      },
      useAuth: false,
    },
  );
  author.set(authInfo);
  return authInfo;
}
