import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

// 我的资产
export function getMyProduct(params: any) {
  return request(`/${SERVER.product}/${API_VERSION}/productDef/queryMyProduct`, {
    method: "POST",
    data: params,
    useAuth: true,
  });
}

export type MyProductCombinationParams = {
  channels: [];
  content?: string;
  pageNum: number;
  pageSize: number;
};
export type GetMyProductCombinationParams = {
  channels: number[];
  content?: string;
  pageNum: number;
  pageSize: number;
  assetType?: number; // 20 权益
};
export type MyProductCombinationItem = {
  adminLabels: any;
  attachId: string;
  author: string;
  authorId: string;
  avatar: string;
  castingQty: number;
  coauthor: string;
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: 0 | 1;
  beginDate?: string;
  endDate?: string;
  status?: number; // 1有效  2销毁
  id: number;
  /** 标签分组：10-图片类；20-音频类；30-视频类；40-3D类；50-文本类 */
  labelGroup: 10 | 20 | 30;
  licenseId: string;
  limitBuyQty: number;
  ownerId: string;
  price: number;
  prodDescribe: string;
  prodInfo: string;
  prodLabels: null;
  prodName: string;
  publishingDate: null;
  publishingQty: number;
  purchasedQty: number;
  registerCode: string;
  symbol: null;
  txHash: string;
  updatedAt: string;
  updatedBy: string;
  rare: 10 | 20 | 30 | 40 | 50;
  [key: string]: any;
};

// 我的资产列表  分类分页 （即返回列表为每个商品包含数量）
export function getMyProductCombinationList(params: GetMyProductCombinationParams) {
  return request<PaginationResult<MyProductCombinationItem>>(`/${SERVER.product}/${API_VERSION}/productDef/queryMyProductDef`, {
    method: "GET",
    params,
  });
}

export type MyProductListByProductIdParams = {
  channels: [];
  content?: string;
  pageNum: number;
  pageSize: number;
  refProductDefId: number;
};

export type GetMyProductListByProductIdParams = {
  channels: number[];
  content?: string;
  pageNum: number;
  pageSize: number;
  refProductDefId: number;
};

export type MyProductListByProductItem = {
  adminLabels: any;
  attachId: string;
  author: string;
  authorId: string;
  avatar: string;
  castingQty: number;
  coauthor: string;
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  channel: number;
  deleted: number;
  getDate: string;
  id: number;
  labelGroup: number;
  licenseId: string;
  limitBuyQty: string;
  ownerId: string;
  price: number;
  prodDescribe: string;
  prodInfo: string;
  prodLabels: null;
  prodName: string;
  publishingDate: string;
  publishingQty: string;
  refProductDefId: number;
  registerCode: string;
  symbol: string;
  tokenId: string;
  tokenIndex: number;
  txHash: string;
  updatedAt: string;
  updatedBy: string;
  rare: 10 | 20 | 30 | 40 | 50;
  composeStatus: number;
  showType: number; // 10 按商家 20 按平台
  [key: string]: any;
};

// 我的资产 每个同类商品下的不同编号商品列表
export function getMyProductListByProductId(params: GetMyProductListByProductIdParams) {
  return request<PaginationResult<MyProductListByProductItem>>(`/${SERVER.product}/${API_VERSION}/productDef/queryMyProductListPage`, {
    method: "GET",
    params,
  });
}
