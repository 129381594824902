import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type ProductPageProdRespParams = {
  /** 排序规则,是否升序 */
  asc?: boolean;
  /** 渠道 */
  channel?: number;
  /** 创建时间范围起点 */
  createdAtBegin?: string;
  /** 创建时间范围终点 */
  createdAtEnd?: string;
  /** 排序字段 */
  field?: string;
  /** 渠道-大于等于 */
  geChannel?: number;
  /** 渠道-小于等于 */
  leChannel?: number;
  /** 所有人ID */
  ownerId?: string;
  /** 当前页码 */
  pageNum?: number;
  /** 当前页面数据量 */
  pageSize?: number;
  /** 商品定义ID */
  productDefId?: number;
  /** 上架标志（1：上架；2：下架） */
  publishStatus?: number;
  /** 修改时间范围起点 */
  updatedAtBegin?: string;
  /** 修改时间范围终点 */
  updatedAtEnd?: string;
  searchContent?: string;
  unComposed?: boolean;
  channels?: number[];
};

export type ProductResp = {
  approved: string;
  approvedId: string;
  /** 作品图片（封面）ID */
  attachId: string;
  author: string;
  /** 发行数量 */
  castingQty: number;
  /** 获得渠道(10:铸造;20:购买;30转增;40:空投;50:盲盒) */
  channel: number;
  coauthor: string;
  contractAddress: string;
  /** 获得时间 */
  getDate: string;
  id: number;
  /** 标签分组：10-图片类；20-音频类；30-视频类；40-3D类；50-文本类 */
  labelGroup: number;
  mint: string;
  mintId: string;
  owner: string;
  ownerId: string;
  /** 上架价格 */
  price: string;
  prodDescribe: string;
  /** 作品文件ID */
  prodFileId: string;
  prodInfo: string;
  prodLabels: string;
  prodName: string;
  /** 作品描述(富文本) */
  prodRichTextId: string;
  refProductDefId: number;
  symbol: string;
  tokenId: string;
  /** token索引位置 */
  tokenIndex: string;
  /** 该资产未被使用 */
  composeStatus: number;
};

// 个人资产
export function getProductPageProdResp(params: ProductPageProdRespParams) {
  return request<PaginationResult<ProductResp>>(
    `/${SERVER.product}/${API_VERSION}/product/pageProdResp`,
    {
      method: 'GET',
      params,
    },
  );
}
