import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';
import type { BoxInfo } from './queryBoxPublishDetailByID';

export type BoxPublishParams = {
  /** 排序规则,是否升序 */
  asc?: boolean;
  /** 发行方 */
  boxAuthor?: string;
  /** 盲盒名称 */
  boxName?: string;
  /** 创建时间范围起点 */
  createdAtBegin?: string;
  /** 创建时间范围终点 */
  createdAtEnd?: string;
  /** 排序字段 */
  field?: string;
  /** 当前页码 */
  pageNum?: number;
  /** 当前页面数据量 */
  pageSize?: number;
  /** 可购买时间-end */
  saleabelEndTime?: string;
  /** 可购买时间-start */
  saleabelStartTime?: string;
  /** 盲盒状态:1.上架;2.下架;-1.已售罄 */
  status?: number;
  /** 修改时间范围起点 */
  updatedAtBegin?: string;
  /** 修改时间范围终点 */
  updatedAtEnd?: string;
  leStatus?: number;
};

export function getBoxPublish(params?: BoxPublishParams) {
  return request<PaginationResult<BoxInfo>>(
    `/${SERVER.product}/${API_VERSION}/boxPublish/noAuth/page`,
    {
      method: 'GET',
      useAuth: false,
      params,
    },
  );
}
