type Device = {
  isMobile: boolean; // 移动设备  手机、平板
  isWeChat: boolean; // 微信
  isPc: boolean; // pc
  isAndroid: boolean; // 安卓
  isIphone: boolean; // 苹果手机
};

// 是不是微信环境
const isWeChat = () => {
  const ua = navigator.userAgent.toLowerCase();

  return ua.includes('micromessenger');
};

// 是不是安卓
const isAndroid = () => {
  const ua = navigator.userAgent;

  return ua.includes('Android') || ua.includes('Adr');
};

// 是不是苹果手机  不包含平板
const isIphone = () => {
  const ua = navigator.userAgent;

  return ua.includes('iPhone') && !ua.includes('ipad');
};

// 是不是移动设备
const isMobile = () => {
  return 'ontouchstart' in document.documentElement;
};

// 是不是PC
const isPc = () => {
  return !isMobile();
};

// TODO: 设备类型判断
const device: Device = {
  isMobile: isMobile(),
  isWeChat: isWeChat(),
  isPc: isPc(),
  isAndroid: isAndroid(),
  isIphone: isIphone(),
};

export default device;
