import { ResponseError } from 'umi-request';
import { notification } from 'antd';
import httpErrorHandler from './http';
import bizHandler from './biz';

const errorHandler = (error: ResponseError) => {
  const silent = Boolean(error.request?.options?.silent);
  if (silent) throw error;

  // 网络错误
  if (error.message === 'Failed to fetch') {
    notification.error({
      message: '网络异常',
      description: `${error.message}:${error.request?.url}`,
    });
  }

  if (error.type === 'HttpError') httpErrorHandler(error);

  if (error.type === 'BizError') bizHandler(error);

  throw error;
};

export default errorHandler;
