import author from '@/utils/lib-author';
import { RequestInterceptor } from 'umi-request';

export const useAuthorization: RequestInterceptor = (url, options) => {
  const { useAuth = true } = options;

  if (useAuth) {
    options.headers = {
      ...options.headers,
      authorization: author.getToken(),
    };
  }

  return {
    url,
    options,
  };
};
