import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

// 打开我的盲盒藏品
export function postOpenBox(params: number[], silent = false) {
  return request<true>(
    `/${SERVER.product}/${API_VERSION}/product/boxOpen`,
    {
      method: 'POST',
      data: params,
      silent: silent
    },
  );
}
