import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

// 商品详情
export function getPublish(id: any) {
  return request(
    `/${SERVER.product}/${API_VERSION}/publishMgt/queryPublishMgtByRegisterId/${id}`,
    {
      method: 'GET',
      useAuth: true,
    },
  );
}
