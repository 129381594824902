import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type PublishProductPayload = {
  price: number;
  qty: number;
  refRegisterId: number;
  registerCode: string;
  salesableTime?: string;
  code?: string;
  refProductDefId?: number;
};

export function postPublishProduct(data: PublishProductPayload) {
  return request(
    `/${SERVER.product}/${API_VERSION}/publishMgt/publishProduct`,
    {
      method: 'POST',
      data,
    },
  );
}
