import React, { FC } from 'react';
import DefaultNav from './DefaultNav';
import PayNav from './PayNav';
import { useModel } from "@@/plugin-model/useModel";

export enum HEAD_NAVS {
  DEFAULT,
  PAY
}

const HeaderNav: FC = () => {
  const nav = useModel(
    'header',
    s => s.settings.nav
  );

  switch (nav) {
    case HEAD_NAVS.PAY:
      return <PayNav/>;

    case HEAD_NAVS.DEFAULT:
    default:
      return <DefaultNav/>;
  }
}

export default HeaderNav;
