import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export function getRemainingQtyByRegisterId(ID: string | number) {
  return request<number>(
    `/${SERVER.product}/${API_VERSION}/product/queryRemainingQtyByRegisterId/${ID}`,
    {
      method: 'GET',
    },
  );
}
