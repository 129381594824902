import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export interface postRegisterTokenType {
  token: string;
  passwd: string;
  passwdConfirm: string;
  phoneRegion: string;
  inviterId?: string;
}

export async function postRegisterToken(params: postRegisterTokenType) {
  const result = await request(
    `/${SERVER.user}/${API_VERSION}/users/registerToken`,
    {
      method: 'POST',
      data: {
        ...params,
        accountType: 10,
      },
      useAuth: false,
    },
  );
  return result;
}
