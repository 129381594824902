import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';
import type { BoxInfo } from './queryBoxPublishDetailByID';

export function getBoxPublishByRegisterID(id: string | number) {
  return request<BoxInfo>(
    `/${SERVER.product}/${API_VERSION}/boxPublish/detail/${id}/count`,
    {
      method: 'GET',
    },
  );
}
