import { Spin } from 'antd';
import S from './styles.less';

export default (props: any) => {
  return (
    <div className={S.loadingPage}>
      <Spin tip='页面加载中' size='small'></Spin>
    </div>
  );
};
