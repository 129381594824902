import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

// 商品详情
export function getProductDetail(id: any) {
  return request(
    `/${SERVER.product}/${API_VERSION}/productDef/productDefDetail/${id}`,
    {
      method: 'GET',
      useAuth: false,
    },
  );
}
