import { getShopByID, ShopDetail } from "@/services/user/getShopByID";
import React from "react";
import { useModel } from "umi";

export default function () {
  const [shopInfo, setShopInfo] = React.useState<ShopDetail>();
  const user = useModel("@@initialState", (s) => s.initialState?.currentUser);

  const getShophInfo = async () => {
    if (!user?.id) return;
    const res = await getShopByID(user?.shopId as string);
    setShopInfo(res);
  };

  React.useEffect(() => {
    getShophInfo();
  }, [user]);

  return { shopInfo, getShophInfo };
}
