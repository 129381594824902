import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

// 我的资产头像列表
export function getMyProductAvatars(params: any) {
  return request(`/${SERVER.product}/${API_VERSION}/market/productDef/queryMyProductAvatar`, {
    method: "GET",
    params,
    useAuth: true,
  }).then((res) => {
    // const list = res.data.records.map((item) => (item.labelGroup === 10 ? item.prodFileId || item.attachId : item.attachId));
    return res.data;
  });
}
