import { RequestInterceptor } from 'umi-request';

export const useCredentials: RequestInterceptor = (url, options) => {

  if (!options.credentials) {
    options.credentials = 'include';
  }

  return {
    url,
    options,
  };
};
