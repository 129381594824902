import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export function checkUser(account: string) {
  return request<boolean>(
    `/${SERVER.user}/${API_VERSION}/users/${account}/check`,
    {
      method: 'GET',
      throwIfBizDataIsFalse: false,
    },
  );
}
