type Return<R, E> = readonly [E, null] | readonly [null, R];

function catcher<R, E = Error>(fn: () => R): Return<R, E>;
function catcher<P extends any[], R, E = Error>(fn: (...args: P) => R, args: P): Return<R, E>;
function catcher<P extends any[], R, E = Error>(fn: (...args: P) => R, args?: P): Return<R, E> {
  const callArgs = args ?? ([] as unknown as P);
  try {
    const result = fn(...callArgs);
    return [null, result];
  } catch (e: unknown) {
    return [e as E, null];
  }
}

export default catcher;
