import { request } from 'umi';
import { API_VERSION, SERVER } from '@/services';

type InviterInfoType = {
  avatar: string;
  inviterId: string;
  id: string;
  name: string;
  nickname: string;
};

export type InviterAirDropType = {
  approved?: string;
  approvedId?: string;
  attachId?: string;
  author?: string;
  castingQty?: number;
  channel?: number;
  coauthor?: string;
  composeStatus?: number;
  composeTime?: string;
  contractAddress?: string;
  createdAt?: string;
  createdBy?: string;
  deleted?: number;
  getDate?: string;
  id?: number;
  labelGroup?: number;
  mint?: string;
  mintId?: string;
  owner?: string;
  ownerId?: string;
  price?: number;
  prodDescribe?: string;
  prodFileId?: string;
  prodInfo?: string;
  prodLabels?: string;
  prodName?: string;
  prodRichTextId?: string;
  purchasePrice?: number;
  rare?: 10 | 20 | 30 | 40;
  refActivityName?: string;
  refProductDefId?: number;
  symbol?: string;
  tokenId?: string;
  updatedAt?: string;
  updatedBy?: string;
  inviter?: InviterInfoType;
};

export async function inviterAirDrop() {
  return request<InviterAirDropType[]>(
    `/${SERVER.product}/${API_VERSION}/product/inviterAirDrop`,
    {
      method: 'GET',
    },
  );
}
