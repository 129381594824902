import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

interface dataType {
  prodName: string;
  refActivityId: number;
}

// 报名现场活动
export function postApplyLive(data: dataType) {
  return request<boolean>(`/${SERVER.product}/${API_VERSION}/apply/applyLive`, {
    method: "POST",
    data,
  });
}
