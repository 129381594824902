import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export type PostAddressPayload = {
  provinceCode: string;
  provinceCn: string;
  cityCode: string;
  cityCn: string;
  regionCode: string;
  regionCn: string;
  contactName: string;
  contactPhone: string;
  detailedAddress: string;
  userId: string;
  userName: string;
  id?: string;
  isDefault?: 0 | 1;
};

export type DeliveryAddressItem = {
  cityCn: string;
  cityCode: string;
  contactName: string;
  contactPhone: string;
  detailedAddress: string;
  id: string;
  isDefault: 0 | 1;
  provinceCn: string;
  provinceCode: string;
  regionCn: string;
  regionCode: string;
  userId: string;
  userName: string;
};

// 新增/编辑
export function saveDeliveryAddress(data: PostAddressPayload) {
  return request<boolean>(
    `/${SERVER.user}/${API_VERSION}/users/saveDeliveryAddress`,
    {
      method: 'POST',
      data,
    },
  );
}

// 列表
export function getDeliveryAddress(userId: string) {
  return request<DeliveryAddressItem[]>(
    `/${SERVER.user}/${API_VERSION}/users/getDeliveryAddress/${userId}`,
    {
      method: 'GET',
    },
  );
}

// 切换默认
export function switchAsDefaultDeliveryAddress(data: {
  id: string;
  isDefault: 1;
}) {
  return request<DeliveryAddressItem[]>(
    `/${SERVER.user}/${API_VERSION}/users/switchDefaultDeliveryAddress`,
    {
      method: 'POST',
      data,
    },
  );
}

// 删除地址
export function deleteDeliveryAddress(id: string) {
  return request<boolean>(
    `/${SERVER.user}/${API_VERSION}/users/deleteDeliveryAddress/${id}`,
    {
      method: 'delete',
    },
  );
}
