import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';
import { ProductDef } from './getProductDefByRegisterId';

// 商品详情
export function getMyProductDetail(id: any) {
  return request(`/${SERVER.product}/${API_VERSION}/productDef/myProductDetail/${id}`, {
    method: 'GET',
    useAuth: true,
  });
}

export type GetProductAssetsByCouponRes = {
  couponAndProductVOS: {
    couponTokenId: string;
    isOwner: boolean;
    productId: string;
    productTokenId: string;
  }[];
  productDefVo: ProductDef;
};
// 根据权益id获取当前用户包含该权益的资产列表（包含未持有的）
export function getProductAssetByCoupon(couponProductDefId: string, refParentProductDefId: string) {
  return request<GetProductAssetsByCouponRes>(`/${SERVER.product}/${API_VERSION}/productDef/getMyProductAssetByCoupon`, {
    method: 'GET',
    params: { couponProductDefId, refParentProductDefId },
    useAuth: true,
  });
}
// 资产内的权益详情
export function getMyProductDetailById(id: any) {
  return request(
    `/${SERVER.product}/${API_VERSION}/productDef/myProductDefById`,
    {
      method: 'GET',
      params: { refProductDefId: id },
      useAuth: true,
    },
  );
}
