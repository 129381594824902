import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export type PutUserPayload = Partial<{
  acDid: string;
  avatar: string;
  did: string;
  email: string;
  id: string;
  name: string;
  orgCert: number;
  phone: string;
  realName: string;
  role: string;
  signature: string;
  state: number;
  sysInvite: string;
  userCert: number;
  nickname: string;
  birthday: string;
  refProductDefId: number | null;
}>;

export function putUser(data: PutUserPayload) {
  return request<boolean>(`/${SERVER.user}/${API_VERSION}/users`, {
    method: 'PUT',
    data,
  });
}
