import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';
import { RareType } from "@/services/product/queryBoxPublishDetailByID";

export type AirDropProdInfoVosType = Omit<{
  rare: RareType;
  prodFileId: string;
  prodName: string;
  prodInfo: string;
  cauthorArtsit?: { avatar: string; name: string; }[]
} & airdropDetailType, 'airDropProdInfoVos'>

export type airdropDetailType = {
  adminLabels: string[];
  attachId: string;
  author: string;
  authorId: string;
  avatar: string;
  code: string;
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: number;
  exeDate: string;
  id: number;
  labelGroup: number;
  owner: string;
  ownerId: string;
  ownerName: string;
  prodLabels: string[];
  prodName: string;
  qty: number;
  refProductDefId: number;
  showDate: string;
  showType: number;
  status: number;
  txHash: string;
  type: 10 | 20 | 30 | 40;
  updatedAt: string;
  updatedBy: string;
  beginDate: string;
  endDate: string;
  currentTime: string;
  airDropInfo: string;
  prodRichTextId?: string;
  prodDescribe?: string;
  airDropProdInfoVos: AirDropProdInfoVosType[];
  directBeginTime: string;
  directEndTime: string;
  directQuota: number;
};

// 空投单详情
export function getAirdropDetailById(id: string | number) {
  return request<airdropDetailType>(
    `/${SERVER.product}/${API_VERSION}/airDropMgt/noAuth/detail/${id}`,
    {
      method: 'get',
      useAuth: false,
    },
  );
}
