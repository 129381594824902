import { request } from 'umi';
import { API_VERSION, SERVER } from '@/services';
import { ORG_STATE } from '@/constants/org';
import { USER_CERT, USER_TYPE } from '@/constants/user';

export type InviterTotalItem = {
  accountType: USER_TYPE;
  userCert: USER_CERT;
  orgCert: ORG_STATE;
  total: number;
};

export function getInviterTotal() {
  return request<InviterTotalItem[]>(
    `/${SERVER.user}/${API_VERSION}/users/inviterTotal`,
    {
      method: 'GET',
    },
  );
}
export function getInviterList(params) {
  return request<InviterTotalItem[]>(
    `/${SERVER.user}/${API_VERSION}/users/invite/list`,
    {
      method: 'GET',
      params,
    },
  );
}
export function getInviteRank(params) {
  return request<InviterTotalItem[]>(
    `/${SERVER.user}/${API_VERSION}/users/invite/rank`,
    {
      method: 'GET',
      params,
    },
  );
}
