import { useModel } from 'umi';
import { getTransferList } from '@/services/product';
import React from 'react';

export default function noGirftsList() {
  const [list, setList] = React.useState<any>({});
  const user = useModel('@@initialState', (s) => s.initialState?.currentUser);

  const initList = () => {
    getTransferList().then((res) => {
      const newList = res.reduce((all, next) => {
        all[next?.productDefId] = next;
        return all;
      }, {} as any);
      setList(newList);
    });
  };

  const noGirfts = (refProductDefId: number) => {
    if (list[refProductDefId]) {
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    if (user) initList();
  }, [user]);

  return {
    list,
    noGirfts,
  };
}
