import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export type CertsPayload = {
  bankCardNo: string;
  checkCode: string;
  idCard: string;
  idCardType: string;
  name: string;
  phone: string;
};

export function postCerts(data: CertsPayload) {
  return request<boolean>(`/${SERVER.user}/${API_VERSION}/certs`, {
    method: 'POST',
    data,
  });
}
