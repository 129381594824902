import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

export function getUserSuccessApplyCount(airdropId: number | string) {
  return request<number>(
    `/${ SERVER.product }/${ API_VERSION }/apply/getUserSuccessApply`,
    {
      method: "GET",
      params: { airdropId }
    }
  );
}
