// @ts-nocheck
import React from 'react';
import initialState from '/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/.umi-production/plugin-initial-state/models/initialState';
import model0 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/enums";
import model1 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/header";
import model2 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/manageInfo";
import model3 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/messages";
import model4 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/noGirftsList";
import model5 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/page";
import model6 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/realNameCert";
import model7 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/socket";
import model8 from "/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/src/models/userDetail";
// @ts-ignore
import Dispatcher from './helpers/dispatcher';
// @ts-ignore
import Executor from './helpers/executor';
// @ts-ignore
import { UmiContext } from './helpers/constant';

export const models = { '@@initialState': initialState, 'enums': model0, 'header': model1, 'manageInfo': model2, 'messages': model3, 'noGirftsList': model4, 'page': model5, 'realNameCert': model6, 'socket': model7, 'userDetail': model8 };

export type Model<T extends keyof typeof models> = {
  [key in keyof typeof models]: ReturnType<typeof models[T]>;
};

export type Models<T extends keyof typeof models> = Model<T>[T]

const dispatcher = new Dispatcher!();
const Exe = Executor!;

export default ({ children }: { children: React.ReactNode }) => {

  return (
    <UmiContext.Provider value={dispatcher}>
      {
        Object.entries(models).map(pair => (
          <Exe key={pair[0]} namespace={pair[0]} hook={pair[1] as any} onUpdate={(val: any) => {
            const [ns] = pair as [keyof typeof models, any];
            dispatcher.data[ns] = val;
            dispatcher.update(ns);
          }} />
        ))
      }
      {children}
    </UmiContext.Provider>
  )
}
