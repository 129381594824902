import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export function canTransferForBenefit(benefitIds: Array<string | number>) {
  return request<Record<string, boolean>>(
    `/${ SERVER.product }/${ API_VERSION }/product/canTransferForBenefit`,
    {
      method: 'GET',
      params: {
        productDefIds: benefitIds.join(',')
      },
      throwIfBizDataIsFalse: false,
      silent: true,
    },
  );
}
