import author from "@/utils/lib-author";
import * as signalR from "@microsoft/signalr";
import appConfig from "../appConfig";

export type EventSourceListener = (this: EventSource, connection: MessageEvent) => any;

class Socket {
  static endpoint = `/hubs/notify`;
  protected connection: signalR.HubConnection | null = null;

  async create(listener: EventSourceListener) {
    const accessToken = author.getTrimmedToken();
    if (!accessToken) return;
    const API_HOST = await appConfig.get('API_HOST');
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API_HOST}${Socket.endpoint}`, {
        accessTokenFactory: () => accessToken,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    this.connection.on('Notify', listener);
    this.start()
  }
  start() {
    this.connection && this.connection.start().then(() => {
      console.log('WebSocket Connected...')
    }).catch(err => {
      console.error('Websocket Connection err:', err)
    })
  }
  close() {
    this.connection && this.connection.stop()
  }
}

export default new Socket();
