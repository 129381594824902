import { useSetState } from 'ahooks';
import { Dict } from '@/services/foundation';

export type DictMap = Record<string, Dict[]>;

/**
 * ! 用于保存枚举表，以保持数据同步
 * ! 不要直接通过useModel使用，使用useEnums来获取相应枚举
 * */
function enums() {
  // 保存的枚举
  const [dict, setDict] = useSetState<DictMap>({});

  return [dict, setDict] as const;
}

export default enums;
