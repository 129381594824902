import { request } from 'umi';
import { API_VERSION, SERVER } from '@/services';
import { ProductDefItem } from '.';

export type ShowListItemType = ProductDefItem & {
  appType?: string;
  optTime?: string;
  productDefId: number;
  remark?: string;
  srcCode?: string;
  srcId?: string;
};

// 获取转赠黑名单
export async function getTransferList() {
  return request<ShowListItemType[]>(`/${SERVER.product}/${API_VERSION}/appOpt/getTransferList`, {
    method: 'get',
  });
}
