import { AuthInfo, LoginWithCheckCode } from '.';
import author from '@/utils/lib-author';
import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export interface postLoginRegister extends LoginWithCheckCode {
  // accountType?: number;
  password?: string;
  username?: string;
}

export async function postLoginRegister(params: postLoginRegister) {
  const authInfo = await request<AuthInfo>(
    `/${SERVER.user}/${API_VERSION}/auths/loginRegister`,
    {
      method: 'POST',
      params: {
        ...params,
        accountType: 10,
        loginPlatform: 4,
      },
      useAuth: false,
    },
  );
  author.set(authInfo);
  return authInfo;
}
