import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export type ResetPasswordPayloads = {
  channel: 'phone' | 'email';
  channelValue: string;
  checkCode: string;
  passwd: string;
  passwdConfirm: string;
  regionCode: string;
};

export function resetPassword(data: ResetPasswordPayloads) {
  return request<boolean>(`/${SERVER.user}/${API_VERSION}/users/forget`, {
    method: 'POST',
    data,
  });
}
