export { requestConfig as request } from '@/utils/lib-request';
export { getInitialState, initialStateConfig } from './initial';
import { matchRoutes } from 'react-router-config';
import bp from '@/utils/buriedPoint';
import catcher from '@/utils/catcher';
import * as Sentry from "@sentry/react";
import pkg from "../package.json";
import { getProdEnv } from './utils/lib-env';

export function onRouteChange(meta: any) {
    const { location, routes } = meta;
    const routeMatch = matchRoutes(routes, location.pathname)?.pop();
    if (!routeMatch) return;
    // 上报pv
    catcher(() => bp.sendPV(routeMatch));
}

const envVersion = getProdEnv();
Sentry.init({
    enabled: true,
    release: '0.0.1',
    dsn: "https://f094b9bc98a42ea5ff9c19ae050139ef@sentry.rivdap.cn/4",
    environment: envVersion,
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    beforeSend(event) {
        if (envVersion === 'develop') return null;
        return event;
    },
});