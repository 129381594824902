export enum ModuleConfigEnum {
  Announcement = 1, //公告模块
  Activity = 2, // 活动模块
  Market = 3, // 市场模块
  Recommend = 4, // 首页推荐模块
  UserNotice = 5, // 用户须知
  AboutUs = 6, // 关于我们
  ContactUs = 7, // 联系我们
  MyInvitation = 8, // 我的邀约模块
  BuyNotice = 9, // 购买须知
}
