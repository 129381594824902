import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';
import { USER_TYPE } from '@/constants/user';

export type RegisterPayload = {
  checkCode: string;
  regionCode: string;
  name: string;
  passwd: string;
  passwdConfirm: string;
  phone: string;
  accountType: USER_TYPE;
  sysInvite?: string;
};

export function postRegister(data: RegisterPayload) {
  data.accountType = 10;
  return request<boolean>(`/${SERVER.user}/${API_VERSION}/users/regist`, {
    method: 'POST',
    data,
    useAuth: false,
  });
}
