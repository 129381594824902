import { request } from "umi";
import { API_VERSION, SERVER } from "@/services";

type QueryProductRespByIdDataType = {
  status: number; // 1 有效  2 销毁
  couponLink?: string;
}

export function queryProductRespById(id: number) {
  return request<QueryProductRespByIdDataType>(
    `/${SERVER.product}/${API_VERSION}/product/queryProductRespById/${id}`,
    {
      method: 'GET',
    })
}
