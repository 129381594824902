import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

export type ComposeProdInfoOwnNum = {
  hadComposedCount: number;
  productCountGroupVos: Array<{
    count: number;
    refProductDefId: number;
  }>;
};

export function getComposeProdInfoOwnNumById(id: number | string) {
  return request<ComposeProdInfoOwnNum>(`/${SERVER.product}/${API_VERSION}/activities/compose/ownNum/${id}`, {
    method: "GET",
  });
}
