import { getAppConfig } from '@/services/config';

class Config {
  private loaded = false;
  private _conf = {} as AppConfig;

  async init(force = false) {
    if (this.loaded && !force) return;
    await this.fresh();
    this.loaded = true;
  }

  async fresh() {
    this._conf = await getAppConfig();
    console.log(this._conf)
    this.loaded = true;
    return this.get();
  }

  get(): AppConfig;
  get<T extends keyof AppConfig>(key: T): AppConfig[T];
  get(key?: keyof AppConfig) {
    return key ? this._conf[key] : this._conf;
  }
}

export default new Config();
