import { ResponseInterceptor } from 'umi-request';

class BizError extends Error {
  type: string = 'BizError';

  constructor(public response: Response, public data?: any) {
    super(data?.message ?? 'biz error');
  }
}

const SUCCESS_CODE = 200;
export const useBizCode: ResponseInterceptor = async (response, options) => {
  const { useBiz = true, throwIfBizDataIsFalse = true } = options;
  if (!useBiz) return response;

  if (response.status === 200) {
    const data = await response?.clone().json();
    if (
      data?.code !== SUCCESS_CODE ||
      (throwIfBizDataIsFalse && data?.data === false)
    )
      throw new BizError(response, data);
  }

  return response;
};
