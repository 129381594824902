import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type ProductDef = {
  adminLabels: string;
  attachId: string;
  author: string;
  avatar: string;
  castingQty: number;
  castingDate: string;
  coauthor: string;
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: number;
  id: number;
  labelGroup: number;
  licenseId: string;
  mint: string;
  mintId: string;
  price: number;
  prodDescribe: string;
  prodInfo: string;
  prodLabels: string[];
  prodName: string;
  publishingQty: number;
  symbol: string;
  updatedAt: string;
  updatedBy: string;
};

export function getProductDefByRegisterId(id: number | string) {
  return request<ProductDef>(
    `/${SERVER.product}/${API_VERSION}/productDef/queryProductDefByRegisterId/${id}`,
    {
      method: 'GET',
    },
  );
}
