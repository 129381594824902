import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type QueryMyCreationPayload = {
  registerIdList: number[];
};

export type MyCreationItem = {
  // 可上架数量
  canPublishQty: number;
  // 上架中数量
  onSaleQty: number;
  // 备案申请单ID
  registerId: number;
  // 已售数量
  soldQty: number;
};

export function queryMyCreation(data: QueryMyCreationPayload) {
  return request<MyCreationItem[]>(
    `/${SERVER.product}/${API_VERSION}/productDef/queryMyCreation`,
    {
      method: 'POST',
      data,
    },
  );
}
