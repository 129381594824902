import { useCallback, useState } from 'react';

function page() {
  const [key, setKey] = useState(Date.now());

  const updateKey = useCallback(() => setKey(Date.now()), [setKey]);

  return { key, updateKey };
}

export default page;
