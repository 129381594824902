import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

type ListMyBoxProductAggParams = {
  channel?: number;
}

export type ListMyBoxProductAggItem = {
  adminLabels: string[],
  adminLabelsStr: string,
  attachId: string,
  boxAuthor: string,
  boxAuthorAvatar: string,
  boxInfo: string,
  boxName: string,
  boxPrice: number,
  boxQty: number,
  brandLogo: string,
  brandName: string,
  brandUrl: string,
  createdAt: string,
  createdBy: string,
  currentTime: string,
  deleted: number,
  delicateConfigTime: string,
  discountOverTime: string,
  discountPrice: number,
  id: number,
  mqTime: string,
  offDate: string,
  openRemind: number,
  openSms: number,
  preQty: number,
  preStartTime: string,
  prodDescribe: string,
  prodRichTextId: string,
  productLogicType: number,
  publishDate: string,
  publishingQty: number,
  purchasedQty: number,
  saleableTime: string,
  updatedAt: string,
  updatedBy: string
}

// 我的盲盒藏品-聚合-不分页
export function getListMyBoxProductAgg(params: ListMyBoxProductAggParams) {
  return request<ListMyBoxProductAggItem[]>(
    `/${SERVER.product}/${API_VERSION}/productDef/listMyBoxProductAgg`,
    {
      method: 'GET',
      params,
    },
  );
}
