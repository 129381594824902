import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';

export type UserBindPayloads = {
  channel: 'phone' | 'email';
  channelValue: string;
  checkCode: string;
};

export function bind(data: UserBindPayloads) {
  return request<boolean>(`/${SERVER.user}/${API_VERSION}/users/bind`, {
    method: 'POST',
    data,
  });
}
