import { API_VERSION, SERVER } from "@/services";
import { request } from "umi";
import { USER_TYPE } from "@/constants/user";

export type CurrentUserOrgInfo = {
  id: number;
  name: string;
  oid: string;
  orgAdmin: boolean;
  state: number;
};

export type CurrentUserInfo = {
  acDid: string;
  avatar: string;
  accountAddress: string;
  did: string;
  id: string;
  name: string;
  realName: string;
  phone: string;
  email: string;
  orgs: CurrentUserOrgInfo[];
  state: number;
  token: string;
  lastLoginTime: number;
  signature: string;
  // !注意：这里接口返回的为string, 不是int
  accountType: `${USER_TYPE}`;
  refMerchantId?: number; // 只有商家才会有
  shopId?: string;
};

export function getCurrentUser() {
  return request<CurrentUserInfo>(`/${SERVER.user}/${API_VERSION}/users/login`, {
    method: "GET",
  });
}
