import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';
import { USER_TYPE } from '@/constants/user';

export type UserInfo = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deleted: number;
  name: string;
  email: string;
  phone: string;
  realName: string;
  state: number;
  did: string;
  acDid: string;
  avatar: string;
  lastLoginTime: number;
  accountType: USER_TYPE;
  accountAddress: string;
  signature: string;
  loginTime: number;
  createdBy: string;
  updatedBy: string;
  userCert: number;
  nickname: string;
  birthday: string;
  phoneRegion: string;
  deliveryAddressCount: number;
  refProductDefId: number;
  refMerchantId?: number;  // 只有商户有
};

export function getUserById(id: string) {
  return request<UserInfo>(`/${SERVER.user}/${API_VERSION}/users/${id}`, {
    method: 'GET',
  });
}
