import { request } from 'umi';
import { API_VERSION, SERVER } from '@/services';

export type ValidUserInfo = {
  id: string;
  username: string;
};

export function getValidUserInfo(attachId: string) {
  return request<ValidUserInfo[]>(
    `/${SERVER.user}/${API_VERSION}/usersInfoFile/validUserInfo/${attachId}`,
    {
      method: 'GET',
    },
  );
}
