import { RequestInterceptor } from 'umi-request';
import joinPath from "@/utils/joinPath";
import appConfig from "@/utils/appConfig";
import * as process from "process";
export const useApiHost: RequestInterceptor = (url, options) => {
  const defaultEnable = process.env.NODE_ENV !== 'development';
  const { useApiHost: enable = defaultEnable } = options;

  if (enable) {
    url = joinPath(appConfig.get('API_HOST'), url);
  }

  return {
    url,
    options,
  };
};
