import { request } from "umi";
import { API_VERSION, SERVER } from "@/services";

export type ShopHostDetail = {
  /** 证书 */
  certificate: string;
  /** 创建时间 */
  createdAt: string;
  /** 记录创建人账号ID */
  createdBy: string;
  /** 删除标记 */
  deleted: number;
  /** 独立站域名 */
  host: string;
  /** id */
  id: string;
  /** 店铺id-即租户id */
  shopId: string;
  /** 站点类型，PC、Mobile */
  siteType: string;
  /** 更新时间 */
  updatedAt: string;
  /** 记录修改人账号ID */
  updatedBy: string;
};

export type ShopDetail = {
  /** 账户地址(40个Hex字符) */
  accountAddress: string;
  /** 管理员手机号 */
  adminPhone: string;
  /** 头像 */
  avatar: string;
  /** 店铺编码，全局唯一，会作为独立站的域名前缀使用 */
  code: string;
  /** 创建时间 */
  createdAt: string;
  /** 记录创建人账号ID */
  createdBy: string;
  /** 删除标记 */
  deleted: number;
  /** 分布式身份ID */
  did: string;
  /** id */
  id: number;
  /** 店铺名称 */
  name: string;
  /** 店铺配置信息 */
  shopHosts: ShopHostDetail[];
  /** 更新时间 */
  updatedAt: string;
  /** 记录修改人账号ID */
  updatedBy: string;
};
export const getShopByID = (id: string) => {
  return request<ShopDetail>(`/${SERVER.user}/${API_VERSION}/shop/byId/${id}`, {
    method: "GET",
  });
};
