import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';
import { USER_CERT } from '@/constants/user';

export type UserCertInfo = {
  id: string;
  status: USER_CERT;
  bankCardNo: string;
  createdAt: string;
  deleted: 0;
  idCard: string;
  idCardType: string;
  name: string;
  phone: string;
  updatedAt: string;
  reason: string;
};

export function getMyCerts() {
  return request<UserCertInfo>(`/${SERVER.user}/${API_VERSION}/certs/my`, {
    method: 'GET',
  });
}
