import type { RequestConfig } from '@@/plugin-request/request';
import type { IOnionMiddleware } from 'umi-request';
import { useAuthorization, useApiHost, useCredentials, useDapPlatform } from './interceptors/request';
import { useBizCode } from './interceptors/response';
import { useBizDataOnly } from './middleware';
import errorHandler from './errorHandler';

// fix umi-request中间件声明错误
type IRequestConfig = Omit<RequestConfig, 'middlewares'> & {
  middlewares?: IOnionMiddleware[];
};

// SEE: https://pro.ant.design/zh-CN/docs/request
export const requestConfig: IRequestConfig = {
  requestInterceptors: [ useCredentials, useAuthorization, useApiHost, useDapPlatform ],
  responseInterceptors: [ useBizCode ],
  middlewares: [ useBizDataOnly ],
  errorHandler,
  errorConfig: {
    adaptor: () => ({ success: true }),
  },
};
