import React, { FC, useMemo } from "react";
import "./index.less";
import { Menu } from "antd";
import type { ItemType } from "antd/lib/menu/hooks/useItems";
import { NavLink } from "umi";
import { ModuleConfigEnum } from "@/utils/themeConfig/dict";
import themeConfig from "@/utils/themeConfig";

type NavItem = ItemType & {
  moduleid?: ModuleConfigEnum;
}

const DefaultNav: FC = () => {
  const navs: NavItem[] = [
    {
      key: '/index',
      label: <NavLink to='/index'>{themeConfig?.get('LAYOUT_CONFIG')?.menuHomeName || '首页'}</NavLink>
    },
    {
      key: '/market',
      label: <NavLink to='/market'>{themeConfig?.get('LAYOUT_CONFIG')?.menuMarketName || '市场'}</NavLink>,
      moduleid: ModuleConfigEnum.Market,
    },
    {
      key: '/events',
      label: <NavLink to='/events'>{themeConfig?.get('LAYOUT_CONFIG')?.menuActivityName || '活动'}</NavLink>,
      moduleid: ModuleConfigEnum.Activity,
    },
    {
      key: '/news/index',
      label: <NavLink to='/news/index'>公告</NavLink>,
      moduleid: ModuleConfigEnum.Announcement,
    },
  ];
  const hiddenIDs: ModuleConfigEnum[] = themeConfig.get('GENERAL_CONFIG')?.pcConfig ?? [ModuleConfigEnum.Announcement];
  const finalNavs = useMemo(() => {
    return navs.filter(nav => !hiddenIDs.includes(nav.moduleid as ModuleConfigEnum));
  }, [hiddenIDs]);

  return (
    <Menu
      mode="horizontal"
      items={ finalNavs }
      selectedKeys={ [] }
      className='dap-header-nav-menu'
    />
  )
}

export default DefaultNav;
