import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export function canTransferForProduct(productIds: string[]) {
  return request<Record<string, boolean>>(
    `/${ SERVER.product }/${ API_VERSION }/product/canTransferForProduct`,
    {
      method: 'GET',
      params: {
        productDefIds: productIds.join(',')
      },
      silent: true,
    },
  );
}
