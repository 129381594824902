import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';
import author from '@/utils/lib-author';
import { USER_TYPE } from '@/constants/user';

type LoginRequiredPayloads = {
  accountType: USER_TYPE;
};

export type LoginWithPassword = {
  username: string;
  password: string;
  regionCode: string;
};

export type LoginWithCheckCode = {
  mobile: string;
  code: string;
  regionCode?: string;  // 发送验证码时传这个字段
  phoneRegion?: string; // 注册传参用这个字段
};

export type LoginPayload = LoginRequiredPayloads &
  (LoginWithPassword | LoginWithCheckCode);

export type AuthInfo = {
  access_token?: string;
  expires_in?: string;
  id_token?: string;
  refresh_expires_in?: string;
  refresh_token?: string;
  scope?: string;
  session_state?: string;
  token: string;
  token_type?: string;
};

export async function postAuthLogin(params: LoginPayload) {
  params.accountType = 10;
  const authInfo = await request<AuthInfo>(
    `/${SERVER.user}/${API_VERSION}/auths/login`,
    {
      method: 'POST',
      params,
      useAuth: false,
      silent: true,
    },
  );
  author.set(authInfo);
  return authInfo;
}
