import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

type Params = {
  ownerId: string;
  refAirDropMgtId: number | string;
};
//  根据用户id和空投id查询申领信息
export function findUserAirdropStatus(params: Params) {
  return request(`/${SERVER.product}/${API_VERSION}/apply/findUserApply`, {
    method: 'POST',
    data: params,
    useAuth: true,
  });
}
