import React, { FC } from 'react';
import './index.less';
import { Menu } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { NavLink } from 'umi';

const navs: ItemType[] = [
  {
    key: '/pay',
    label: '收银台',
  },
];
const PayNav: FC = () => {
  return <Menu mode='horizontal' items={navs} selectedKeys={[]} className='dap-header-nav-menu' />;
};

export default PayNav;
