import { AuthInfo } from '@/services/user';
import { localStorage } from '@/utils/lib-storage';
import { decode } from 'js-base64';
import { history } from 'umi';

class Author {
  private _storageKey = 'authInfo';
  private _exp?: number;

  private _expCheck(auth: AuthInfo): boolean {
    try {
      if (!this._exp) {
        const [, jwtPayloadStr] = auth.access_token.split('.');
        const jwtPayload = JSON.parse(decode(jwtPayloadStr));
        this._exp = Number(jwtPayload.exp) * 1000;
      }

      return Date.now() < this._exp!;
    } catch (e) {
      // 上述parse失败默认为通过检查
      return true;
    }
  }

  getTrimmedToken() {
    return this.get('token').replace('Bearer ', '').replace(' ', '')
  }
  getToken() {
    return this.get('token');
  }

  set(data: AuthInfo) {
    localStorage.setItem(this._storageKey, data);
  }

  get(): AuthInfo;
  get<T extends keyof AuthInfo>(key: T): AuthInfo[T];
  get(key?: keyof AuthInfo) {
    // 第一次读取就判断是否路由携带token, 携带则去除token进行跳转
    // 适用于商品详情页
    if(location.href.includes('?token=')) {
      const locationSearchParams = new URLSearchParams(location.search);
      const queryToken = locationSearchParams.get("token");
      if(queryToken) {
        this.set({'token': queryToken})
        locationSearchParams.delete("token");
        const newSearchUrl = locationSearchParams.toString();
        const redirectUrl =
          `${window.location.pathname}` + newSearchUrl
            ? `?${newSearchUrl}`
            : "";
        history.replace(redirectUrl)
      }
    }
    const auth = localStorage.getItem(this._storageKey);

    if (!auth) return;
    if (!this._expCheck(auth)) return this.remove();

    return key ? auth[key] : auth;
  }

  remove() {
    this._exp = undefined;
    localStorage.removeItem(this._storageKey);
  }
}

export default new Author();
