import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

export type WhiteListVerifyPayload = {
  // 应用类型：10，销售上架；20，盲盒活动；30，合成活动；40，空投活动  50，现场活动
  appType: 10 | 20 | 30 | 40 | 50;
  productDefId?: number | string;
  srcId?: number | string;
};

export function whiteListVerify(data: WhiteListVerifyPayload) {
  return request<boolean>(`/${SERVER.product}/${API_VERSION}/appOpt/verify`, {
    method: "POST",
    data,
    throwIfBizDataIsFalse: false,
  });
}
export function whiteListVerifyNoPre(data: WhiteListVerifyPayload) {
  return request<boolean>(`/${SERVER.product}/${API_VERSION}/appOpt/verifyNoPre`, {
    method: "POST",
    data,
    throwIfBizDataIsFalse: false,
  });
}
