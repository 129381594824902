import { postTrackingSave, TrackingSaveReq } from '@/services/log/postTrackingSave';
import { matchRoutes } from 'react-router-config';
import { uuid } from '@/utils/uuid';
import to from "await-to-js";

type RouteMatch = Exclude<ReturnType<typeof matchRoutes>, null>[number];
type IRouteMatch = Omit<RouteMatch, 'route'> & {
  route: RouteMatch['route'] & { title?: string };
};

class BuriedPoint {
  private readonly deviceId: string;
  private userId: string | undefined = undefined;
  private router: (IRouteMatch & { tick: number }) | null = null;

  constructor() {
    this.deviceId = localStorage.getItem('bp_deviceId') ?? uuid();
    localStorage.setItem('bp_deviceId', this.deviceId);
  }

  setUserId(userId?: string) {
    this.userId = userId;
  }

  send(data: Omit<TrackingSaveReq, 'userId' | 'deviceId' | 'data'> & { data?: object }) {
    const { pathname, route, params } = this.router!;
    to(
      postTrackingSave({
        ...data,
        userId: this.userId,
        deviceId: this.deviceId,
        data: JSON.stringify({
          device: 'pc',
          pathname,
          pageName: route.title,
          route: route.path,
          routeParams: params,
          ...data.data,
        }),
      })
    )

  }

  sendPV(routeMatch: IRouteMatch) {
    // 用作重定向的路由不需要记录
    const blackList = ['/'];
    if (blackList.includes(routeMatch.pathname)) return;

    const tick = Date.now();

    // 上报离开页停留时长
    if (this.router) {
      this.send({
        action: 'pv',
        bpid: 'stayOn',
        type: 'pageView',
        begin: this.router.tick,
        end: tick,
      });
    }

    // 上报新页面pv
    this.router = { ...routeMatch, tick };
    this.send({
      action: 'pv',
      bpid: 'pv',
      type: 'pageView',
    });
  }
}

export default new BuriedPoint();
