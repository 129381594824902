import { ResponseError } from 'umi-request';
import { notification } from 'antd';
import { history } from 'umi';
import { LOGIN } from '@/constants/pages';
import author from '@/utils/lib-author';

// TODO: 添加http状态码翻译
const CODE_MAP: Record<number, string> = {};
const httpErrorHandler = (error: ResponseError) => {
  const { status } = error.response;

  notification.error({
    message: '请求失败',
    description: error.data?.message || CODE_MAP[status],
  });

  // 特殊状态码处理
  switch (status) {
    case 401:
      const from = history.location.pathname;
      author.remove();

      // if (from.startsWith('/user')) {
        history.replace({
          pathname: LOGIN,
          query: {
            redirect: from,
          },
        });
      // }
      break;
  }
};

export default httpErrorHandler;
