import { API_VERSION, SERVER } from '@/services';
import axios from 'axios';

export type TrackingSaveReq = {
  /** pv/record */
  action: 'pv' | 'record';
  /** 开始时间戳 */
  begin?: number;
  /**
   * 埋点ID(按页面或按按钮定义)
   * 命名规则 module_target_event
   * 示例：product_buyButton_clk
   * */
  bpid?: string;
  /** 埋点要求数据 */
  data?: string;
  /** 访客终端ID */
  deviceId?: string;
  /** 结束时间戳 */
  end?: number;
  /** pageView-页面曝光量、pageClick-页面点击量、buttonClick-按钮点击量 */
  type: 'pageView' | 'pageClick' | 'buttonClick';
  /** 已登录用户ID */
  userId?: string;
};

export function postTrackingSave(data: TrackingSaveReq) {
  return axios<boolean>(`/${SERVER.log}/${API_VERSION}/tracking/save`, {
    method: 'POST',
    data,
  });
}
