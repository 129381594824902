import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type BoxProdInfo = {
  /** 商品图片ID */
  attachId: string;
  /** 作者 */
  author: string;
  /** 作者Id */
  authorId: string;
  /** 作者头像 */
  avatar: string;
  /** Token合约部署地址 */
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: number;
  id: number;
  /** 商品所有者(地址) */
  owner: string;
  /** 商品所有者ID */
  ownerId: string;
  /** 所有者 */
  ownerName: string;
  /** 商品名称 */
  prodName: string;
  /** 可售数量(上架中) */
  publishQty: number;
  /** 发行数量 */
  qty: number;
  /** 关联-盲盒操作单ID */
  refBoxPublishMgtId: number;
  /** 关联-商品定义ID */
  refProductDefId: number;
  /** 合约交易编号 */
  txHash: string;
  updatedAt: string;
  updatedBy: string;
  prodInfo: string;
  prodDescribe: string;
  rare: RareType;
  prodFileId: string;
  labelGroup: number;
};

export type RareType = 10 | 20 | 30 | 40 | 50

export type BoxInfo = {
  /** 商品图片ID */
  attachId: string;
  /** 盲盒发行方 */
  boxAuthor: string;
  /** 盲盒发行方头像ID */
  boxAuthorAvatar: string;
  /** 盲盒简介 */
  boxInfo: string;
  /** 盲盒名称 */
  boxName: string;
  /** 盲盒价格(单位:分) */
  boxPrice: number;
  /** 商品描述列表 */
  boxProdInfos: BoxProdInfo[];
  /** 盲盒发行总数 */
  boxQty: number;
  /** 创建时间 */
  createdAt: string;
  /** 记录创建人账号ID */
  createdBy: string;
  /** 服务器当前时间 */
  currentTime: string;
  /** 删除标记 */
  deleted: number;
  /** id */
  id: number;
  /** 下架时间 */
  offDate: string;
  /** 当前在售总数 */
  onBoxPublishingQty: number;
  /** 盲盒详情 */
  prodDescribe: string;
  /** 上架时间 */
  publishDate: string;
  /** 可售数量(上架中) */
  publishingQty: number;
  /** 可购买时间 */
  saleableTime: string;
  /** 累计售出总数 */
  soldBoxPublishQty: number;
  /** 盲盒状态:1.上架;2.下架; */
  status: number;
  /** 更新时间 */
  updatedAt: string;
  /** 记录修改人账号ID */
  updatedBy: string;
  /** 上架数量 */
  qty: number;
  adminLabels: string[];
  delicateConfigTime: string;
  prodRichTextId: string;
  preQty: number;
  preStartTime: string;
  appOptProducts: BoxProdInfo[];
};

export function queryBoxPublishDetailByID(id: string | number) {
  return request<BoxInfo>(
    `/${SERVER.product}/${API_VERSION}/boxPublish/noAuth/detail/${id}`,
    {
      method: 'POST',
      useAuth: false,
    },
  );
}
