import { request } from 'umi';
import { SERVER, API_VERSION } from '@/services';

export type QueryProductDefParams = {
  asc?: boolean;
  createdAtBegin?: string;
  createdAtEnd?: string;
  field?: string;
  isDelicate?: boolean;
  labelGroup?: number;
  pageNum?: number;
  pageSize?: number;
  priceOrder?: string;
  searchContent?: string;
  updatedAtBegin?: string;
  updatedAtEnd?: string;
};

export type ProductDefItem = {
  adminLabels: string[];
  attachId: string;
  author: string;
  authorId: string;
  avatar: string;
  canBuy: boolean;
  castingDate: string;
  castingQty: number;
  coauthor: string;
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: number;
  id: number;
  labelGroup: 10 | 20 | 30 | 40;
  licenseId: string[];
  lowerPrice: number;
  mint: string;
  mintId: string;
  price: number;
  prodDescribe: string;
  prodInfo: string;
  prodLabels: string[];
  prodName: string;
  publishingQty: number;
  refRegisterId: number;
  remainingQty: number;
  salesableTime: string;
  symbol: string;
  updatedAt: string;
  updatedBy: string;
  upperPrice: number;
  rare: number;
  prodFileId: string;
};

export function queryProductDef(params?: QueryProductDefParams) {
  return request<PaginationResult<ProductDefItem>>(
    `/${SERVER.product}/${API_VERSION}/productDef/pageProductDef`,
    {
      method: 'GET',
      params,
    },
  );
}
