import { API_VERSION, SERVER } from "@/services";
import { request } from "umi";

export type getRealNameConfigType = {
  attachId?: string;
  author?: string;
  authorId?: string;
  avatar?: string;
  cert?: number;
  code?: string;
  content?: {
    additionalProp1?: any;
    additionalProp2?: any;
    additionalProp3?: any;
  };
  createdAt?: string;
  createdBy?: string;
  functionType?: number;
  id?: number;
  imOrder?: number;
  mode?: number;
  prodName?: string;
  refProductDefId?: number;
  remark?: string;
  srcType?: number;
  title?: string;
  type?: number;
  updatedAt?: string;
  updatedBy?: string;
  url?: string;
};

export type functionTypeType = 10 | 20 | 30 | 40 | 50;
/**
 *  functionType 10-交易购买(市场、盲盒)；20-参加空投(申领模式-空投报名影响)；30-参与合成(合成)；40-发起转赠(商品转赠、权益转赠、分享转赠); 50-现场活动(参与报名)
 */
export function getRealNameConfigList() {
  return request<getRealNameConfigType[]>(`/${SERVER.foundation}/${API_VERSION}/im/getCertList`, {
    method: "GET",
  });
}
