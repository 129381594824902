import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type ApplyAirdropProductPayload = {
  prodName: string,
  refAirDropMgtId: number,
  refProductDefId?: number
};

export type ApplyAirdropProductSuccessData = {
  author: string;
  attachId: string;
  castingQty: number;
  couponVOList: Array<{
    couponCount: number;
    couponName: string;
  }>;
  id: number;
  prodName: string;
  tokenId: string;
  tokenIndex: string;
}

export async function applyAirdropProduct(data: ApplyAirdropProductPayload) {
  const result = await request<ApplyAirdropProductSuccessData[]>(
    `/${SERVER.product}/${API_VERSION}/apply/saveDirectGet`,
    {
      method: 'POST',
      data,
    },
  );

  return result[0];
}
