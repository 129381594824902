import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";

//  20-市场销售预约报名场景;45-空投预约报名场景;50-盲盒预约报名场景;60-合成预约报名场景
export type ApplyAppointmentData = {
  publishType: number;
  srcId: number;
  srcName: string;
};

export enum reservationEnum {
  REMIND_BUY = 20, //市场销售预约报名场景
  AIR_DROP = 40, //空投申领场景
  REMIND_AIR_DROP = 45, //空投预约报名场景
  REMIND_BLIND_BOX = 50, //盲盒预约报名场景
  REMIND_COMPOSE = 60, //合成预约报名场景
  APPLY_LIVE = 70, //现场-报名
  REMIND_LIVE = 71, //现场活动-预约
  CLOCK_IN = 80, //现场-打卡
  REMIND_CLOCK_IN = 81, //现场活动-打卡-预约
}

export type GetAppointmentData = {
  publishType: reservationEnum;
  ownerId: any;
  srcId: number;
};

export function applyAppointment(data: ApplyAppointmentData) {
  return request<boolean>(`/${SERVER.product}/${API_VERSION}/apply/saveRemind`, {
    method: "POST",
    data,
    throwIfBizDataIsFalse: false,
  });
}

export function getAppointment(params: GetAppointmentData) {
  return request<boolean>(`/${SERVER.product}/${API_VERSION}/apply/findUserRemindInfo`, {
    method: "GET",
    params,
    throwIfBizDataIsFalse: false,
  });
}
