import { HEAD_NAVS } from '@/components/Header/Nav';
import { useSetState } from "ahooks";

export type HeaderSetting = {
  // 导航条类型
  nav: HEAD_NAVS;
  // 滚动超过多少像素时不透明
  settledThreshold: number;
  // 是否不透明
  forceSettled: boolean;
  // 是否显示边框线
  border: boolean;
}

type SettingNode = {
  id: string;
  setting: Partial<HeaderSetting>;
}

const DEFAULT_SETTING: HeaderSetting = Object.freeze({
  nav: HEAD_NAVS.DEFAULT,
  settledThreshold: 100,
  forceSettled: false,
  border: true,
})

const QUEUE: SettingNode[] = [];

function header() {
  const [ settings, setSettings ] = useSetState<HeaderSetting>(DEFAULT_SETTING);

  const updateSetting = () => {
    const mergedSettings = QUEUE.reduce(
      (setting, cur) => Object.assign(setting, cur.setting),
      { ...DEFAULT_SETTING }
    );
    setSettings(mergedSettings);
  }

  const cover = (id: string, setting: Partial<HeaderSetting>) => {
    const index = QUEUE.findIndex(node => node.id === id);
    index === -1
      ? QUEUE.push({ id, setting })
      : QUEUE[index].setting = setting;
    updateSetting();
  }

  const release = (id: string) => {
    const index = QUEUE.findIndex(node => node.id === id);
    if (index === -1) return;

    QUEUE.length = index;
    updateSetting();
  }

  return {
    settings,
    cover,
    release
  }
}

export default header;
