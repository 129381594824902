export default function joinPath(
  base: string = '',
  ...paths: string[]
): string {
  const headReg = /^\/*/;
  const tailReg = /\/*$/;

  return paths.filter(Boolean).reduce((res, cur) => {
    return [res.replace(tailReg, ''), cur.replace(headReg, '')].join('/');
  }, base);
}
