import { getUserById, putUser, PutUserPayload, UserInfo } from "@/services/user";
import { useRequest } from "ahooks";
import { useModel } from "@@/plugin-model/useModel";

function userDetail() {
  const userID = useModel('@@initialState', s => s.initialState?.currentUser?.id) as string;

  const { data: userDetail, runAsync: refreshUserDetail } = useRequest(
    () => getUserById(userID!),
    {
      ready: userID !== undefined,
    },
  );

  const updateUserDetail = (data: Omit<PutUserPayload, 'id'>) => putUser({
    ...data,
    id: userID
  })

  return {
    userDetail,
    refreshUserDetail,
    updateUserDetail,
  }

}

export default userDetail;
