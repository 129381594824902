import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type invitationAirdropDetailType = {
  adminLabels: string[];
  attachId: string;
  author: string;
  authorId: string;
  avatar: string;
  code: string;
  contractAddress: string;
  createdAt: string;
  createdBy: string;
  deleted: number;
  exeDate: string;
  id: number;
  labelGroup: number;
  owner: string;
  ownerId: string;
  ownerName: string;
  prodLabels: string[];
  name: string;
  qty: number;
  refProductDefId: number;
  showDate: string;
  showType: number;
  status: number;
  txHash: string;
  type: 10 | 20;
  updatedAt: string;
  updatedBy: string;
  beginDate: string;
  endDate: string;
  activityInfoVoes: any[];
};

// 邀约空投单详情
export function getInvitationAirdropDetailById(id: string | number) {
  return request<invitationAirdropDetailType>(`/${SERVER.product}/${API_VERSION}/activity/noAuth/detail/${id}`, {
    method: 'get',
    useAuth: false,
  });
}
