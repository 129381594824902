import { request } from 'umi';
import { API_VERSION, SERVER } from '@/services';

export interface GetArtisListType {
  asc?: boolean;
  createdAtBegin?: string;
  createdAtEnd?: string;
  field?: string;
  name?: string;
  pageNum?: number;
  pageSize?: number;
  updatedAtBegin?: number;
  updatedAtEnd?: number;
}

export function getArtisList(params: GetArtisListType) {
  return request(`/${SERVER.product}/${API_VERSION}/artist/page`, {
    method: 'GET',
    params,
  });
}
//----
export interface AddArtisType {
  avatar: string;
  info: string;
  name: string;
  richTextId: string;
}

export function addArtis(data: AddArtisType) {
  return request(`/${SERVER.product}/${API_VERSION}/artist/add`, {
    method: 'POST',
    data,
  });
}

//---
export interface UpdateArtisType {
  id: number;
  avatar: string;
  info: string;
  name: string;
  richTextId: string;
}

export function updateArtis(data: UpdateArtisType) {
  return request(`/${SERVER.product}/${API_VERSION}/artist/update`, {
    method: 'POST',
    data,
  });
}
