import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

export type SharingInfo = {
  /** 活动二维码id */
  activityMgtId: number;
  /** 名称 */
  name: string;
  /** 状态：0-关闭；1-启用（新增默认）；2-完成（已赠送） */
  status: number;
  /** 提交时间 */
  updateTime: string;
  toAccountAddress: string;
  toAvatar: string;
  toId: string;
  toName: string;
  qty: number;
}

export function getSharingInfoList(refProductDefId: string, name: string) {
  return request<SharingInfo[]>(`/${SERVER.product}/${API_VERSION}/scanTransfer/getSharingInfoList`, {
    method: 'GET',
    params: {
      refProductDefId,
      name,
      pageNum: 1,
      pageSize: 10000
    },
  });
}
