import { RequestInterceptor } from 'umi-request';

export const useDapPlatform: RequestInterceptor = (url, options) => {

  options.headers = {
    ...options.headers,
    'dap-platform': 'PcWeb'
  };

  return {
    url,
    options,
  };
};
