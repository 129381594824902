import { getConfiguration } from '@/services/config';

const code = [20, 30, 40, 50, 60, 70];
export enum ThemeConfigEum { // const lint报错
  PLATFORM_CONFIG = 20,
  LAYOUT_CONFIG = 30,
  PLATFORM_PICS = 40,
  LOGIN_CONFIG = 50,
  PARTNERS_CONFIG = 60,
  GENERAL_CONFIG = 70,
}
export type ThemeConfigItem = {
  [key: string]: any;
};
export type ThemeConfigList = {
  [key in keyof typeof ThemeConfigEum]: ThemeConfigItem;
};

class ThemeConfig {
  private _conf = {} as ThemeConfigList;

  async init() {
    const data = await Promise.all(code.map(getConfiguration));
    data.forEach((_, index) => (this._conf[code[index]] = data[index]?.content));
    console.log('ThemeConfig:', this._conf);

    this.enableConfigs();
  }

  get(): ThemeConfigList;
  get<T extends keyof typeof ThemeConfigEum>(key: T): ThemeConfigItem;
  get(key?: keyof typeof ThemeConfigEum) {
    return key ? this._conf[ThemeConfigEum[key]] : this._conf;
  }

  /* =========== 配置相关设置 ========== */
  private enableConfigs() {
    this.enablePrimaryColor();
  }

  // 设置主色
  private enablePrimaryColor() {
    const {darkMode: scheme, primaryColor} = this.get('GENERAL_CONFIG') || {};
    if (scheme !== 'light' || !primaryColor) return;

    const id = 'color-config';
    let style = document.getElementById(id) as HTMLStyleElement;
    if (!style) {
      style = document.createElement('style');
      style.id = id;
      document.head.appendChild(style);
    }
    style.innerHTML = `
      :root:root {
        --primary-color: ${primaryColor};
        --primary-color-hover: ${primaryColor};
      }
    `
  }
}

export default new ThemeConfig();
